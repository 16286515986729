import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { ConfigProvider } from 'antd';
import { BrowserRouter } from 'react-router-dom';

import { colorBackground, colorSelected, colorSecondary } from './const/colors';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: colorSelected,
                },
                components: {
                    Button: {
                        colorPrimary: colorSelected,
                    },
                    Menu: {
                        darkItemBg: colorBackground,
                        darkPopupBg: colorBackground,
                    },
                    Layout: {
                        siderBg: colorBackground,
                        triggerBg: '#08090d', // Close to background but darker
                    },
                },
            }}
        >
            <App />
        </ConfigProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import axios from 'axios';

export const GetListings = async (collectionSlug: string) => {
    const response = await axios.get(`https://api.duckly.info/dev/${collectionSlug}/list/listings`);
    console.log(response.data);
    return response.data;
};

export const GetStatistics = async (collectionSlug: string) => {
    const response = await axios.get(`https://api.duckly.info/dev/${collectionSlug}/statistics`);
    return response.data;
};

export const GetWalletItems = async (walletAddress: string) => {
    const response = await axios.get(`https://api.duckly.info/dev/wallet/list_collections`, {
        params: { walletAddress },
    });
    return response.data;
};

export default GetListings;

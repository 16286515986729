import * as _ from 'lodash';

import { Spin, Card, Flex, Badge, Divider, Layout, Row, Col, Statistic, StatisticProps, Breadcrumb, theme } from 'antd';
import { useMediaQuery } from 'react-responsive';

import { useState, useEffect } from 'react';

import { GetListings, GetStatistics } from '../apis/backend';

import { capitalizeString } from './Utils';

import CollectionItem from './CollectionItem';
import { parse } from 'path';

const { Content } = Layout;

const raritiesPriority = new Map([
    ['unrevealed', -2],
    ['no rarity', -1],
    ['legendary', 0],
    ['epic', 1],
    ['rare', 2],
    ['uncommon', 3],
    ['common', 4],
]);

interface DisplayListingsProps {
    collectionName: string;
    collectionPath: string;
    statsNames: string[];
    boostsNames: string[];
    isTabletOrMobile: boolean;
}

// TODO: improve statistics responsiveness

const DisplayListings = (props: DisplayListingsProps) => {
    const [listings, setListings] = useState<any[]>([]);
    const [statistics, setStatistics] = useState<any>({});

    // TODO: force order in the traits and format the values

    // TODO: on hover of card, switch image to iframe?
    // TODO: differ empty from loading
    // TODO: investigate why some records have multiple listings
    // TODO: add owners and max supply

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    useEffect(() => {
        setListings([]);
        GetListings(props.collectionPath).then((listings) => setListings(listings));
        GetStatistics(props.collectionPath).then((statistics) => setStatistics(statistics));
    }, [props.collectionPath]);

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    if (listings.length === 0) {
        return (
            <Flex
                align='center'
                justify='center'
                gap='middle'
                style={{ minHeight: isTabletOrMobile ? '720px' : '1080px' }}
            >
                <Spin size='large' />
            </Flex>
        );
    } else {
        const groupedByIds = _.groupBy(listings, (listing) => listing.nft.identifier);
        const dedupedListings = Object.values(groupedByIds).map((listings) =>
            _.minBy(listings, (listing) => parseFloat(listing.priceDollars))
        );
        let groupedListings = _.groupBy(dedupedListings, (listing) =>
            (listing.nft.traits?.rarity || 'No Rarity').toLowerCase()
        );
        return (
            <div style={{ padding: isTabletOrMobile ? '0 5px' : '0 48px' }}>
                <Breadcrumb style={{ margin: '16px 0' }}></Breadcrumb>
                <Row gutter={16}>
                    <Col xs={{ span: 12 }} lg={{ span: 6 }} style={{ margin: isTabletOrMobile ? '5px 0px' : '0' }}>
                        <Card bordered={false} style={{ width: '100' }}>
                            <Flex align='center' justify='center' gap='middle'>
                                <Statistic
                                    title='Floor Price'
                                    suffix={statistics.total.floor_price_symbol}
                                    value={statistics.total.floor_price}
                                    precision={props.isTabletOrMobile ? 0 : 4}
                                />
                            </Flex>
                        </Card>
                    </Col>
                    <Col xs={{ span: 12 }} lg={{ span: 6 }} style={{ margin: isTabletOrMobile ? '5px 0px' : '0' }}>
                        <Card bordered={false} style={{ width: '100' }}>
                            <Flex align='center' justify='center' gap='middle'>
                                <Statistic
                                    title='Market Cap'
                                    suffix='ETH'
                                    value={statistics.total.market_cap.toFixed(2)}
                                    precision={2}
                                />
                            </Flex>
                        </Card>
                    </Col>
                    <Col xs={{ span: 12 }} lg={{ span: 6 }} style={{ margin: isTabletOrMobile ? '5px 0px' : '0' }}>
                        <Card bordered={false} style={{ width: '100' }}>
                            <Flex align='center' justify='center' gap='middle'>
                                <Statistic
                                    title='Last 24h Volume'
                                    suffix='ETH'
                                    value={parseFloat(statistics.intervals[0].volume).toFixed(2)}
                                    precision={props.isTabletOrMobile ? 2 : 4}
                                />
                            </Flex>
                        </Card>
                    </Col>
                    <Col xs={{ span: 12 }} lg={{ span: 6 }} style={{ margin: isTabletOrMobile ? '5px 0px' : '0' }}>
                        <Card bordered={false} style={{ width: '100' }}>
                            <Flex align='center' justify='center' gap='middle'>
                                <Statistic
                                    title='N. Owners'
                                    value={statistics.total.num_owners}
                                    precision={0}
                                    valueStyle={{ alignItems: 'center' }}
                                />
                            </Flex>
                        </Card>
                    </Col>
                </Row>
                <Content>
                    <Breadcrumb style={{ margin: '16px 0' }}></Breadcrumb>
                    <div
                        style={{
                            padding: 24,
                            minHeight: 380,
                            background: colorBgContainer,
                            borderRadius: borderRadiusLG,
                        }}
                    >
                        {Array.from(raritiesPriority.keys())
                            .reverse()
                            .filter((rarity) => groupedListings[rarity] !== undefined)
                            .map((rarity) => (
                                <div>
                                    <Divider orientation='left'>
                                        <h3>{capitalizeString(rarity)}</h3>
                                    </Divider>
                                    <Flex wrap gap='large'>
                                        {_.orderBy(
                                            groupedListings[rarity],
                                            [(listing) => parseFloat(listing.priceDollars)],
                                            ['asc', 'asc', 'asc']
                                        ).map((listing: any) => (
                                            <CollectionItem
                                                key={listing.nft.identifier}
                                                nft={listing.nft}
                                                listing={listing}
                                                collectionName={props.collectionName}
                                                collectionPath={props.collectionPath}
                                                statsNames={props.statsNames}
                                                boostsNames={props.boostsNames}
                                                isTabletOrMobile={isTabletOrMobile}
                                            />
                                        ))}
                                    </Flex>
                                    <br />
                                </div>
                            ))}
                    </div>
                </Content>
            </div>
        );
    }
};

export default DisplayListings;

import { Spin, Card, Flex, Badge, Divider, Layout, Row, Col, Statistic, StatisticProps, Breadcrumb, theme } from 'antd';

import pitGueioSrc from '../content/pitgueio.webp';
import getGueioImage from '../content/get_gueio_image';

const { Meta } = Card;
const { Content } = Layout;

interface CollectionItemProps {
    nft: any;
    listing: any;
    collectionName: string;
    collectionPath: string;
    statsNames: string[];
    boostsNames: string[];
    isTabletOrMobile: boolean;
}

const BadgeColorByRarity = new Map([
    ['No rarity', 'black'],
    ['Common', 'grey'],
    ['Uncommon', 'blue'],
    ['Rare', 'purple'],
    ['Epic', 'magenta'],
    ['Legendary', 'gold'],
]);

const capitalizeString = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

const CollectionItem: React.FC<CollectionItemProps> = (props: CollectionItemProps) => {
    const coverSize = props.isTabletOrMobile ? '160px' : '240px';

    // TODO: find better way to display terrain and level

    let imageDisplay = (
        <img
            className='w-full'
            height='100%'
            loading='lazy'
            decoding='async'
            src={props.nft.image_url}
            style={{ width: coverSize, height: coverSize }}
        ></img>
    );
    switch (props.collectionPath) {
        case 'gueio':
            imageDisplay = (
                <img
                    className='w-full'
                    height='100%'
                    loading='lazy'
                    decoding='async'
                    src={getGueioImage(props.nft)}
                    style={{ width: coverSize, height: coverSize }}
                ></img>
            );
            break;

        case 'pitgueio':
            imageDisplay = (
                <img
                    className='w-full'
                    height='100%'
                    loading='lazy'
                    decoding='async'
                    src={pitGueioSrc}
                    style={{ width: coverSize, height: coverSize }}
                ></img>
            );
            break;
    }

    return (
        <Badge.Ribbon
            text={props.nft.traits?.rarity}
            color={BadgeColorByRarity.get(props.nft.traits?.rarity)}
            key={props.nft.identifier}
        >
            <Card style={{ width: coverSize }} cover={imageDisplay}>
                <Meta title={props.collectionName + ' #' + props.nft.identifier} />
                {props.listing ? (
                    <div>
                        <b>
                            {props.listing.priceToken} {props.listing.price.current.currency}
                        </b>
                        <b> </b>
                        <span style={{ color: 'grey', fontSize: 'smaller' }}>{props.listing.priceDollars} USD</span>
                    </div>
                ) : (
                    <></>
                )}
                {props.nft.traits && props.statsNames?.length ? (
                    <div>
                        <Divider plain>Stats</Divider>
                        {Object.keys(props.nft.traits)
                            .filter((trait) => props.statsNames.includes(trait.toLocaleLowerCase()))
                            .map((trait: any) => (
                                <Flex key={trait} justify='space-between' gap='middle'>
                                    <div>{capitalizeString(trait)}</div>
                                    <b>{props.nft.traits[trait]}</b>
                                </Flex>
                            ))}
                    </div>
                ) : (
                    <></>
                )}
                {props.nft.traits && props.boostsNames?.length ? (
                    <div>
                        <Divider plain>Boosts</Divider>
                        {Object.keys(props.nft.traits)
                            .filter((trait) => props.boostsNames.includes(trait.toLocaleLowerCase()))
                            .map((trait: any) => (
                                <Flex key={trait} justify='space-between' gap='middle'>
                                    <div>{capitalizeString(trait)}</div>
                                    <b>{props.nft.traits[trait]}</b>
                                </Flex>
                            ))}
                    </div>
                ) : (
                    <></>
                )}
                <br />
                <Flex justify='flex-end' align='flex-end' gap='middle'>
                    <a target='_blank' href={props.nft.opensea_url}>
                        View on OpenSea
                    </a>
                </Flex>
            </Card>
        </Badge.Ribbon>
    );
};

export default CollectionItem;

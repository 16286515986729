import * as _ from 'lodash';
import { isAddress } from 'web3-validator';
import { useMediaQuery } from 'react-responsive';

import {
    Spin,
    Card,
    Flex,
    Badge,
    Divider,
    Layout,
    Row,
    Col,
    Statistic,
    StatisticProps,
    Breadcrumb,
    theme,
    Input,
    Empty,
} from 'antd';

import { useState, useEffect } from 'react';

import { GetWalletItems } from '../apis/backend';

import { capitalizeString } from './Utils';

import CollectionItem from './CollectionItem';
import { collectionMap, collectionSlugToPathMap } from '../const/CollectionsInfo';

const { Content } = Layout;
const { Search } = Input;

interface WalletProps {}

// TODO: improve statistics responsiveness

const Wallet = (props: WalletProps) => {
    const [walletAddress, setWalletAddress] = useState<string>('');
    const [inventory, setInventory] = useState<any>({});
    const [isLoading, setIsLoading] = useState<any>(false);

    // TODO: force order in the traits and format the values

    // TODO: improve price display to avoid overflow
    // TODO: on hover of card, switch image to iframe?
    // TODO: differ empty from loading
    // TODO: investigate why some records have multiple listings
    // TODO: add owners and max supply

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const search = (input: any) => {
        setWalletAddress(input);
    };

    useEffect(() => {
        if (walletAddress.trim().length != 0 && isAddress(walletAddress)) {
            setIsLoading(true);
            GetWalletItems(walletAddress).then((listings) => {
                setInventory(listings);
                setIsLoading(false);
            });
        }
    }, [walletAddress]);

    let displayInventory: any = (
        <Flex align='center' justify='center' gap='middle' style={{ minHeight: isTabletOrMobile ? '720px' : '1080px' }}>
            <Empty />
        </Flex>
    );

    if (isLoading) {
        displayInventory = (
            <Flex
                align='center'
                justify='center'
                gap='middle'
                style={{ minHeight: isTabletOrMobile ? '720px' : '1080px' }}
            >
                <Spin size='large' />
            </Flex>
        );
    } else if (Object.keys(inventory).length != 0) {
        displayInventory = Object.keys(inventory).map((terrain) => (
            <div>
                <Divider orientation='left'>
                    <h3>{capitalizeString(terrain)}</h3>
                </Divider>
                <Flex wrap gap='large'>
                    {inventory[terrain].map((nft: any) => (
                        <CollectionItem
                            nft={nft}
                            listing={null}
                            collectionName={collectionMap[collectionSlugToPathMap[nft.collection]].collectionName}
                            collectionPath={collectionSlugToPathMap[nft.collection]}
                            statsNames={collectionMap[collectionSlugToPathMap[nft.collection]].statsNames}
                            boostsNames={collectionMap[collectionSlugToPathMap[nft.collection]].boostsNames}
                            isTabletOrMobile={isTabletOrMobile}
                        />
                    ))}
                </Flex>
                <br />
            </div>
        ));
    }

    return (
        <div style={{ padding: isTabletOrMobile ? '0 5px' : '0 48px' }}>
            <Content>
                <Breadcrumb style={{ margin: '16px 0' }}></Breadcrumb>
                <div
                    style={{
                        padding: 24,
                        minHeight: 380,
                        background: colorBgContainer,
                        borderRadius: borderRadiusLG,
                    }}
                >
                    <Row justify='center'>
                        <Col xs={{ span: 24 }} lg={{ span: 16 }}>
                            <Search
                                placeholder='Paste your wallet address here'
                                variant='filled'
                                enterButton='Search'
                                size='large'
                                status={walletAddress.trim().length === 0 || isAddress(walletAddress) ? '' : 'error'}
                                onSearch={search}
                            />
                        </Col>
                    </Row>
                    {displayInventory}
                </div>
            </Content>
        </div>
    );
};

export default Wallet;

import React, { useState, useEffect } from 'react';

import { useMediaQuery } from 'react-responsive';

import { createBrowserRouter, RouterProvider, Route, Routes, Link, BrowserRouter } from 'react-router-dom';

import logo from './logo.png';
import './App.css';

import { TagOutlined, WalletOutlined, LinkOutlined } from '@ant-design/icons';

import type { MenuProps } from 'antd';
import { Breadcrumb, Layout, Menu, theme, ConfigProvider } from 'antd';
import { colorSecondary } from './const/colors';
import DisplayListings from './components/CollectionListings';
import Wallet from './components/Wallet';
import { collectionMap } from './const/CollectionsInfo';

const { Header, Content, Footer, Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

const defaultSelectedKey = 'land';

const items: MenuItem[] = [
    {
        label: 'On sale',
        key: 'on_sale',
        icon: <TagOutlined />,
        children: Object.values(collectionMap).map((collection) => ({
            key: `${collection.collectionPath}`,
            label: <Link to={collection.collectionPath}>{collection.collectionName}</Link>,
        })),
    },
    {
        label: <Link to='/wallet'>Wallet</Link>,
        key: 'wallet',
        icon: <WalletOutlined />,
    },
    {
        key: 'official_website',
        icon: <LinkOutlined />,
        label: (
            <a href='https://myduckly.com' target='_blank' rel='noopener noreferrer' style={{ color: colorSecondary }}>
                Official website
            </a>
        ),
    },
];

const App: React.FC = () => {
    const [current, setCurrent] = useState(defaultSelectedKey);
    const [collapsed, setCollapsed] = useState(false);

    const onClick: MenuProps['onClick'] = (e) => {
        if (e.key === 'official_website') {
            return;
        }
        console.log(e.key);
        setCurrent(e.key);
    };

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    const MenuComponent = isTabletOrMobile ? (
        <div>
            <Menu
                theme='dark'
                defaultSelectedKeys={[current]}
                defaultOpenKeys={['on_sale']}
                mode='horizontal'
                items={items}
                onClick={onClick}
                style={{ position: 'fixed', zIndex: 1, width: '100%' }}
            />
            <br />
            <br />
        </div>
    ) : (
        <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
            <img src={logo} alt='Logo' style={{ width: '100%' }} />
            <Menu
                theme='dark'
                defaultSelectedKeys={[current]}
                defaultOpenKeys={['on_sale']}
                mode='inline'
                items={items}
                onClick={onClick}
            />
        </Sider>
    );

    return (
        <BrowserRouter>
            <Layout style={{ minHeight: '100vh' }}>
                {MenuComponent}
                <Layout>
                    <Routes>
                        <Route
                            key={'default'}
                            path={'/'}
                            element={
                                <DisplayListings
                                    isTabletOrMobile={isTabletOrMobile}
                                    collectionName={collectionMap[defaultSelectedKey].collectionName}
                                    collectionPath={collectionMap[defaultSelectedKey].collectionPath}
                                    statsNames={collectionMap[defaultSelectedKey].statsNames}
                                    boostsNames={collectionMap[defaultSelectedKey].boostsNames}
                                />
                            }
                        ></Route>
                        {Object.values(collectionMap).map((collection) => (
                            <Route
                                key={collection.collectionPath}
                                path={collection.collectionPath}
                                element={
                                    <DisplayListings
                                        isTabletOrMobile={isTabletOrMobile}
                                        collectionName={collection.collectionName}
                                        collectionPath={collection.collectionPath}
                                        statsNames={collection.statsNames}
                                        boostsNames={collection.boostsNames}
                                    />
                                }
                            ></Route>
                        ))}
                        <Route key={'wallet'} path={'/wallet'} element={<Wallet />}></Route>
                    </Routes>
                    <Footer style={{ textAlign: 'center' }}>
                        {new Date().getFullYear()} This application is provided free of charge as a community tool, not
                        affiliated with the official game producer.
                        <br />
                        <br />
                        Nothing here should be considered financial advice.
                    </Footer>
                </Layout>
            </Layout>
        </BrowserRouter>
    );
};

export default App;

import beachBlue from './beach_blue.webp';
import beachFire from './beach_fire.webp';
import beachGreen from './beach_green.webp';
import beachIce from './beach_ice.webp';
import beachPing from './beach_ping.webp';
import beachPurple from './beach_purple.webp';
import beachWhite from './beach_white.webp';
import beachYellow from './beach_yellow.webp';

import brazilFire from './brazil_fire.webp';
import brazilGreen from './brazil_green.webp';
import brazilIce from './brazil_ice.webp';
import brazilPink from './brazil_pink.webp';
import brazilPurple from './brazil_purple.webp';
import brazilWhite from './brazil_white.webp';
import brazilYellow from './brazil_yellow.webp';

import desertBlue from './desert_blue.webp';
import desertFire from './desert_fire.webp';
import desertGreen from './desert_green.webp';
import desertIce from './desert_ice.webp';
import desertPink from './desert_pink.webp';
import desertPurple from './desert_purple.webp';
import desertWhite from './desert_white.webp';
import desertYellow from './desert_yellow.webp';

import forestFire from './forest_fire.webp';
import forestGreen from './forest_green.webp';
import forestIce from './forest_ice.webp';
import forestPink from './forest_pink.webp';
import forestPurple from './forest_purple.webp';
import forestWhite from './forest_white.webp';
import forestYellow from './forest_yellow.webp';
import forestBlue from './forest_blue.webp';

import launchBlue from './launch_blue.webp';
import launchFire from './launch_fire.webp';
import launchGreen from './launch_green.webp';
import launchIce from './launch_ice.webp';
import launchPink from './launch_pink.webp';
import launchPurple from './launch_purple.webp';
import launchWhite from './launch_white.webp';
import launchYellow from './launch_yellow.webp';

import parkFire from './park_fire.webp';
import parkGreen from './park_green.webp';
import parkBlue from './park_blue.webp';
import parkIce from './park_ice.webp';
import parkPink from './park_pink.webp';
import parkWhite from './park_white.webp';
import parkYellow from './park_yellow.webp';

import schoolBlue from './school_blue.webp';
import schoolFire from './school_fire.webp';
import schoolGreen from './school_green.webp';
import schoolIce from './school_ice.webp';
import schoolPink from './school_pink.webp';
import schoolPurple from './school_purple.webp';
import schoolWhite from './school_white.webp';
import schoolYellow from './school_yellow.webp';

import sweedenBlue from './sweeden_blue.webp';
import sweedenFire from './sweeden_fire.webp';
import sweedenGreen from './sweeden_green.webp';
import sweedenPink from './sweeden_pink.webp';
import sweedenPurple from './sweeden_purple.webp';
import sweedenWhite from './sweeden_white.webp';
import sweedenYellow from './sweeden_yellow.webp';
import sweedenIce from './sweeden_ice.webp';

import gueio0 from './gueio_0.webp';

const imageMap = new Map<string, Map<string, string>>([
    [
        'beach',
        new Map<string, string>([
            ['blue', beachBlue],
            ['red fire', beachFire],
            ['green', beachGreen],
            ['blue ice', beachIce],
            ['ping', beachPing],
            ['diamond', beachPurple],
            ['classic', beachWhite],
            ['yellow', beachYellow],
        ]),
    ],
    [
        'brazil',
        new Map<string, string>([
            ['red fire', brazilFire],
            ['green', brazilGreen],
            ['blue ice', brazilIce],
            ['pink', brazilPink],
            ['diamond', brazilPurple],
            ['classic', brazilWhite],
            ['yellow', brazilYellow],
        ]),
    ],
    [
        'desert',
        new Map<string, string>([
            ['blue', desertBlue],
            ['red fire', desertFire],
            ['green', desertGreen],
            ['blue ice', desertIce],
            ['pink', desertPink],
            ['diamond', desertPurple],
            ['classic', desertWhite],
            ['yellow', desertYellow],
        ]),
    ],
    [
        'forest',
        new Map<string, string>([
            ['red fire', forestFire],
            ['green', forestGreen],
            ['blue ice', forestIce],
            ['pink', forestPink],
            ['diamond', forestPurple],
            ['classic', forestWhite],
            ['yellow', forestYellow],
            ['blue', forestBlue],
        ]),
    ],
    [
        'launch station',
        new Map<string, string>([
            ['blue', launchBlue],
            ['red fire', launchFire],
            ['green', launchGreen],
            ['blue ice', launchIce],
            ['pink', launchPink],
            ['diamond', launchPurple],
            ['classic', launchWhite],
            ['yellow', launchYellow],
        ]),
    ],
    [
        'park',
        new Map<string, string>([
            ['red fire', parkFire],
            ['blue', parkBlue],
            ['green', parkGreen],
            ['blue ice', parkIce],
            ['pink', parkPink],
            ['classic', parkWhite],
            ['yellow', parkYellow],
        ]),
    ],
    [
        'school',
        new Map<string, string>([
            ['blue', schoolBlue],
            ['red fire', schoolFire],
            ['green', schoolGreen],
            ['blue ice', schoolIce],
            ['pink', schoolPink],
            ['diamond', schoolPurple],
            ['classic', schoolWhite],
            ['yellow', schoolYellow],
        ]),
    ],
    [
        'sweden',
        new Map<string, string>([
            ['blue', sweedenBlue],
            ['red fire', sweedenFire],
            ['green', sweedenGreen],
            ['pink', sweedenPink],
            ['diamond', sweedenPurple],
            ['classic', sweedenWhite],
            ['yellow', sweedenYellow],
            ['blue ice', sweedenIce],
        ]),
    ],
]);

const getGueioImage = (nft: any) => {
    console.log(nft, nft.traits, nft.identifier);
    if (nft.identifier === '0' || nft.collection !== 'gueio') {
        console.log('Returning default image');
        return gueio0;
    }
    const { terrain, body } = nft.traits;
    const terrainLower = terrain.toLowerCase();
    const bodyLower = body.toLowerCase();
    if (imageMap.has(terrainLower) && imageMap.get(terrainLower)?.has(bodyLower)) {
        console.log(imageMap.get(terrainLower)?.get(bodyLower));
        return imageMap.get(terrainLower)?.get(bodyLower);
    }
    // Return a default image if the terrain or color is not found in the map
    return gueio0;
};

export default getGueioImage;

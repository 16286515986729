export const collectionMap: Record<
    string,
    { collectionName: string; collectionPath: string; statsNames: string[]; boostsNames: string[] }
> = {
    gueio: {
        collectionName: 'Gueio',
        collectionPath: 'gueio',
        statsNames: ['amount magic', 'energy', 'maximum magic', 'level'],
        boostsNames: [
            'less tax on breed',
            'breeds faster',
            'can give miss in the fight',
            'clean the poo',
            'improve attack',
            'improve shield',
            'increase egg drop',
            'less duckly to create land',
            'less tax on breed',
            'lose less energy',
            'recovers energy faster',
            'slots in the land',
            'spend less on food',
        ],
    },
    land: {
        collectionName: 'Land',
        collectionPath: 'land',
        statsNames: ['amount rune slots', 'durability', 'level'],
        boostsNames: [],
    },
    farm: {
        collectionName: 'Farm',
        collectionPath: 'farm',
        statsNames: ['amount rune slots', 'durability', 'level', 'duckly slots'],
        boostsNames: [],
    },
    reroll_ticket: {
        collectionName: 'Reroll Ticket',
        collectionPath: 'reroll_ticket',
        statsNames: [],
        boostsNames: [],
    },
    carnival_tv: {
        collectionName: 'Carnival TV',
        collectionPath: 'carnival_tv',
        statsNames: [],
        boostsNames: [],
    },
    carnival_trio: {
        collectionName: 'Carnival Trio',
        collectionPath: 'carnival_trio',
        statsNames: [],
        boostsNames: ['faster breeding', 'improve drop egg'],
    },
    fountain: {
        collectionName: 'Fountain',
        collectionPath: 'fountain',
        statsNames: [],
        boostsNames: [],
    },
    apple_tree: {
        collectionName: 'Apple Tree',
        collectionPath: 'apple_tree',
        statsNames: [],
        boostsNames: ['drop apple'],
    },
    pitgueio: {
        collectionName: 'Pit Gueio',
        collectionPath: 'pitgueio',
        statsNames: [],
        boostsNames: [],
    },
};

export const collectionSlugToPathMap: Record<string, string> = {
    gueio: 'gueio',
    landmyduckly: 'land',
    ducklyfarm: 'farm',
    'reroll-ticket-myduckly': 'reroll_ticket',
    carnivaltv: 'carnival_tv',
    carnivaltrio: 'carnival_trio',
    'fountain-myduckly': 'fountain',
    'appletree-myduckly': 'apple_tree',
    pitgueio: 'pitgueio',
};
